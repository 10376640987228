import { Profile, ControlPanel, MyApplications, NonCaptablesValidation, Jobs,NoticesPage } from "../../pages";
import {
  Collaborators,
  Sir,
  Campaigns,
  PublicOffer,
  ConsultPage,
  SRPage,
  GranConsumoPage,
} from '../../pages'
import {
  GuildsPage,
  GuildsIP3Page,
  GuildDetail,
  EEIIDetail,
  EEIIIPPage,
  PaymentsPage,
  ManagersPage,
} from '../../pages'
import { Installers, IP, JCA, Stuck, Users, IP3Hist, PORequests } from '../../pages'
import { Marketers, Supervision, Applications, Tariffs, EEIIPage } from '../../pages'
import { OffersPage, PrivateAreaNavigationGuidePage } from '../../pages'
import { JobsPage } from "../../pages/jobs";

export const getRoutes = (role, permissions) => {
  let router = {}
  switch (role) {
    case 'call_center':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/consult', component: ConsultPage },
          { path: '/tariffs', component: Tariffs },
          { path: '/eeii/:id', component: EEIIDetail },
          { path: '/eeii', component: EEIIPage },
          { path: '/eeii-ip', component: EEIIIPPage },
          { path: '/guilds/:id', component: GuildDetail },
          { path: '/guilds', component: GuildsPage },
          { path: '/guilds-ip3', component: GuildsIP3Page },
          { path: '/collaborators', component: Collaborators },
          { path: '/offers', component: OffersPage },
          { path: '/private-area-navigation-guide', component: PrivateAreaNavigationGuidePage },
        ],
        redirect: '/applications',
      }
      if (permissions.ver_tabla_ip) {
        router.routes = [...router.routes, { path: '/ip', component: IP }]
      }
      if (permissions.ver_tabla_jca) {
        router.routes = [...router.routes, { path: '/jca', component: JCA }]
      }
      break
    case 'selectra':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/consult', component: ConsultPage },
        ],
        redirect: '/applications',
      }

      break
    case 'gss_vt':
    case 'eurofirms':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/consult', component: ConsultPage },
        ],
        redirect: '/applications',
      }

      break
    case 'accom':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/consult', component: ConsultPage },
        ],
        redirect: '/applications',
      }

      break
    case 'soporte_procesos':
      router = {
        routes: [
          { path: '/supervision', component: Supervision },
          { path: '/applications', component: Applications },
          { path: '/eeii/:id', component: EEIIDetail },
          { path: '/eeii', component: EEIIPage },
          { path: '/guilds/:id', component: GuildDetail },
          { path: '/guilds', component: GuildsPage },
          { path: '/profile', component: Profile },
          { path: '/collaborators', component: Collaborators },
        ],
        redirect: '/supervision',
      }
      if (permissions.ver_tabla_no_captables) {
        router.routes = [
          ...router.routes,
          { path: '/non-captables-validation', component: NonCaptablesValidation },
        ]
      }
      break
    case 'e_commerce':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/tariffs', component: Tariffs },
          { path: '/eeii/:id', component: EEIIDetail },
          { path: '/eeii', component: EEIIPage },
          { path: '/guilds/:id', component: GuildDetail },
          { path: '/guilds', component: GuildsPage },
          { path: '/collaborators', component: Collaborators },
        ],
        redirect: '/applications',
      }
      break
    case 'sir':
      router = {
        routes: [
          { path: '/sir', component: Sir },
          { path: '/profile', component: Profile },
        ],
        redirect: '/sir',
      }
      break
    case 'delegado': {
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/control-panel', component: ControlPanel },
          { path: '/my-applications', component: MyApplications },
          { path: '/profile', component: Profile },
          { path: '/sr', component: SRPage },
          { path: '/consult', component: ConsultPage },
        ],
        redirect: '/control-panel',
      }
      break
    }
    case 'gestor_gc': {
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/profile', component: Profile },
          { path: '/sr', component: SRPage },
          { path: '/consult', component: ConsultPage },
          { path: '/gran-consumo', component: GranConsumoPage },
        ],
        redirect: '/applications',
      }
      break
    }
    case 'gestor':
      router = {
        routes: [
          { path: '/applications', component: Applications },
          { path: '/control-panel', component: ControlPanel },
          { path: '/my-applications', component: MyApplications },
          { path: '/profile', component: Profile },
          // { path: '/sr', component: SRPage },
          { path: '/consult', component: ConsultPage },
        ],
        redirect: '/control-panel',
      }

      break
    case 'gestor_ip':
      router = {
        routes: [
          { path: '/profile', component: Profile },
          { path: '/control-panel', component: ControlPanel },
          { path: '/eeii-ip', component: EEIIIPPage },
          { path: '/eeii/:id', component: EEIIDetail },
          { path: '/ip', component: IP },
          { path: '/jca', component: JCA },
          { path: '/ip3-historical', component: IP3Hist },
          { path: '/guilds-ip3', component: GuildsIP3Page },
          { path: '/guilds/:id', component: GuildDetail },
        ],
        redirect: '/control-panel',
      }
      break
    default:
      router = { routes: [{ path: '/profile', component: Profile }], redirect: '/profile' }
  }

  if (permissions['ver_campana']) {
    router.routes.unshift({ path: '/campaigns', component: Campaigns })
  }
  if (permissions['ver_op']) {
    router.routes.unshift({ path: '/public-offer', component: PublicOffer })
  }
  if (permissions['editar_usuario']) {
    router.routes.push({ path: '/users', component: Users })
    router.routes.push({ path: '/installers', component: Installers })
  }

  if(permissions['editar_instalador']){
    router.routes.push({ path: '/installers', component: Installers })
  }

  if (permissions['filtros_comercializadora']) {
    router.routes.push({ path: '/marketers', component: Marketers })
  }
  if (permissions['seguimiento_encalladas']) {
    router.routes.push({ path: '/stuck', component: Stuck })
  }
  if (permissions['gestion_adhesiones_op']) {
    router.routes.push({ path: '/po-requests', component: PORequests })
  }
  if (permissions['ver_pago']) {
    router.routes.push({ path: '/payments', component: PaymentsPage })
  }

  if (permissions['ver_pago']) {
    router.routes.push({ path: '/jobs', component: Jobs })
  }

  if (permissions['ver_tareas']) {
    router.routes.push({ path: '/supervision', component: Supervision })
  }
  if (permissions['crear_gestor']) {
    router.routes = [...router.routes, { path: '/managers', component: ManagersPage }]
  }
  if (permissions['crear_notificaciones']) {
    router.routes.push({ path: '/notices', component: NoticesPage })
  }
  return router
}
